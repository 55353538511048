import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { throttle } from 'lodash';
import { metadefenderService, Authenticator, QueryParams, logService } from '@mdc/services';
import InfiniteScroll from '@mdc/ui/node_modules/react-infinite-scroller';
import ButtonsPlaceholder from '@mdc/ui/src/common/content-placeholder/ButtonsPlaceholder';
import TextInput from '@mdc/ui/src/common/text-input/TextInput';
import { NOTIFICATION_TYPES } from '@mdc/constants';
import { InternalLink } from '@mdc/ui';

import PropTypes from 'prop-types';

import './CveList.scss';

const ITEMS_PER_PAGE = 40;
const INITIAL_PAGE_HEIGHT = 530;
const PLACEHOLDER = 'CVE-XXXX-YYYY';

const CveList = ({ notify, location }) => {
    const { CVEListData } = location?.state || {};
    const { page, filter } = QueryParams();
    const { t, ready } = useTranslation();

    const [displayedItems, setDisplayedItems] = useState(ITEMS_PER_PAGE);
    const [cvesData, setCvesData] = useState(CVEListData || null);
    const [filteredItems, setFilteredItems] = useState(null);
    const [scroll, setScroll] = useState(0);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [showLoader, setShowLoader] = useState(() => {
        return !CVEListData?.length;
    });
    const [inputText, setInputText] = useState(filter || '');
    const [initialLoad, setInitialLoad] = useState(true);
    const [scrollPos, setScrollPos] = useState(0);
    const [scrollTop, setScrollTop] = useState(false);
    const [minPage, setMinPage] = useState(page ? +page : 1);
    const [pageHeight, setPageHeight] = useState(INITIAL_PAGE_HEIGHT);

    const initialPageItems = page ? ITEMS_PER_PAGE * (page - 1) : 0;

    const loadMore = throttle(() => {
        setInitialLoad(false);
        setShowLoader(true);
        setDisplayedItems(displayedItems + ITEMS_PER_PAGE);
        if (scrollTop) {
            if (minPage === 1) {
                return;
            }

            setMinPage(minPage - 1);
            window.scrollTo(0, pageHeight);
        }

        if (scroll.offsetHeight && pageHeight === INITIAL_PAGE_HEIGHT) {
            setPageHeight(scroll.offsetHeight);
        }
    }, 150, { leading: true });

    useEffect(() => {
        (async () => {
            if (cvesData?.length) {
                return;
            }

            try {
                await Authenticator.init();
                const [cvesDataPromise] = metadefenderService.vulnerabilities.getCves();
                const cvesInfo = await cvesDataPromise;
                setCvesData(cvesInfo.data.data.reverse());
                setShowLoader(false);
            } catch (err) {
                logService.error(err); // eslint-disable-line no-console
                notify({
                    message: t('Network error occurred, and your request could not be completed. Please refresh the page'),
                    type: NOTIFICATION_TYPES.CRITICAL
                });

            }
        })();

        if (filter && inputText !== filter) {
            setInputText(filter);
        }
    }, []);

    useEffect(() => {
        if (!cvesData) {
            return;
        }

        if (inputText !== '') {
            filterData();
            window && window.history.replaceState('', '', `?filter=${inputText}&page=${minPage || 1}`);
        } else {
            window && window.history.replaceState('', '', `?page=${minPage || 1}`);
            setFilteredItems(null);
        }
    }, [inputText, cvesData]);

    const getScrollDirection = throttle(() => {
        const distanceFromTop = document.body.getBoundingClientRect().top;

        if (distanceFromTop > scrollPos) {
            setScrollTop(true);
        } else {
            setScrollTop(false);
        }

        setScrollPos(distanceFromTop);

        const bodyOffset = document.body.getBoundingClientRect().top;
        const currentPage = minPage + Math.floor(Math.abs(bodyOffset / pageHeight));
        if (inputText) {
            window && window.history.replaceState('', '', `?filter=${inputText}&page=${currentPage || 1}`);
        } else {
            window && window.history.replaceState('', '', `?page=${currentPage || 1}`);
        }

    }, 150, { leading: true });

    useEffect(() => {
        window.addEventListener('scroll', getScrollDirection, {
            passive: true
        });

        return () => {
            window.removeEventListener('scroll', getScrollDirection, {
                passive: true
            });
        };
    }, [scrollPos, minPage]);

    const filterData = () => {
        const cvesDataTemp = [...cvesData];
        const filteredCvesData = cvesDataTemp.filter((cve) => cve.toLowerCase().indexOf(inputText.toLowerCase()) >= 0);
        setFilteredItems(filteredCvesData);

        filteredCvesData.length <= displayedItems ? setHasMoreData(false) : setHasMoreData(true);
    };

    const noResultsDom = <Col>
        <p>{t('There were no results matching your query')}</p>
    </Col>;

    const cvesDataDomEntries = useMemo(() => {
        if (!cvesData || (inputText !== '' && !filteredItems?.length)) {
            return noResultsDom;
        }

        let cvesDataTemp = [...cvesData];
        let startIndex = initialPageItems;

        if (filteredItems) {
            cvesDataTemp = [...filteredItems];
            startIndex = 0;
        }

        cvesDataTemp.length <= displayedItems ? setHasMoreData(false) : setHasMoreData(true);

        return cvesDataTemp.splice(startIndex, displayedItems).map((cve) => (
            <Col lg={3} md={3} xs={6} key={cve?.id}>
                <div className='cveButton'>
                    <InternalLink
                        to={`/vulnerabilities/${cve}`}
                        state={{ CVEListData: cvesData }}
                    >{cve}</InternalLink>
                </div>
            </Col>));

    }, [cvesData, displayedItems, filteredItems]);


    const LoadingComponent = useMemo(() => {
        if (!showLoader) {
            return null;
        }

        return (
            <Col xs={12} md={12} lg={12} key={'loadingComponent'}>
                <ButtonsPlaceholder numberOfRows={10} />
            </Col>
        );
    }, [showLoader]);

    const cvesDataDom = <Row ref={(ref) => setScroll(ref)}>
        <div className='infiniteScrollWrapper'>
            <InfiniteScroll
                pageStart={minPage}
                loadMore={loadMore}
                initialLoad={initialLoad}
                loader={!scrollTop && LoadingComponent}
                threshold={140}
                useWindow={true}
                hasMore={hasMoreData}
                isReverse={scrollTop}
                getScrollParent={() => scroll}
            >
                <div className='cvesDataWrapper'>
                    {cvesDataDomEntries}
                </div>
            </InfiniteScroll>
        </div>
    </Row>;

    const filterForm = <Row>
        <Col xs={12} md={6} lg={6}>
            <Row>
                <Col className='my-auto filterForm'>
                    <p className='filterLabel'>
                        {t('Filter the CVE vulnerabilities')}
                    </p>
                    <div className='filterInput'>
                        <TextInput
                            onChange={setInputText}
                            placeholder={PLACEHOLDER}
                            initialValue={inputText}
                        />
                    </div>
                </Col>
            </Row>
        </Col>
    </Row>;

    if (!ready) {
        return null;
    }

    return (
        <section className='cveList'>
            <Row>
                <Col md={6} lg={6} sm={8}>
                    <h1>{t('List of supported CVE Vulnerabilities')}</h1>
                    <p
                        // eslint-disable-next-line no-useless-escape
                        dangerouslySetInnerHTML={{ __html: t('Our Vulnerability Assessment technology detects application and file based vulnerabilities before they are installed. <a href=\"https://www.opswat.com/technologies/vulnerability-assessment\">Read more...</a>') }}
                    />
                </Col>
            </Row>
            {filterForm}
            {
                cvesData
                    ? cvesDataDom
                    : <Row className='cvesDataWrapper'>{LoadingComponent}</Row>
            }
        </section>
    );
};

CveList.propTypes = {
    notify: PropTypes.func,
    location: PropTypes.object
};

export default CveList;
