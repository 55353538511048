import { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { metadefenderService, Authenticator, epochToDateTimeGmt, logService } from '@mdc/services';
import { Table, Spinner, CveStatus, CveResolution, CveAffectedVersion, InternalLink } from '@mdc/ui';
import { NOTIFICATION_TYPES } from '@mdc/constants';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import './CveDetails.scss';

const CWE_URL = 'https://www.cve.org/CVERecord';

const CveDetails = (props) => {
    const { t, ready } = useTranslation();
    const { cve, notify, location } = props;
    const { CVEListData } = location?.state || {};

    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [isNonExist, setIsNonExist] = useState(false);
    const [statusCve, setStatusCve] = useState('');
    const [references, setReferences] = useState([]);
    const [products, setProducts] = useState([]);
    const [resolutions, setResolutions] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [hashes, setHashes] = useState({});
    const [info, setInfo] = useState([]);
    const [description, setDescription] = useState('');
    const [realCveValue, setRealCveValue] = useState(cve);
    const [cvesData] = useState(CVEListData);

    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    const setItemRange = (items, item, product, range) => {
        if (items[product] && items[product].min > range.start) {
            items[product].min = range.start;
        } else if (items[product] && items[product].max < range.limit) {
            items[product].max = range.limit;
        } else {
            items[product] = {
                name: item.product.name,
                min: range.start,
                max: range.limit
            };
        }
    };

    const productVendorExtract = useCallback((specs) => {
        const items = {};
        const retailers = [];
        specs.opswat_product_info.forEach((item) => {
            const name = item.vendor?.name;
            const product = item.product?.name?.toUpperCase();

            if (name && !retailers.some((v) => v.name.toUpperCase() === name.toUpperCase())) {
                retailers.push({ name });
            }
            if (!product && !item.range) {
                return;
            }

            item.ranges.forEach((range) => setItemRange(item, item, product, range));
        });

        const productsTable = [];
        /* eslint-disable no-unused-vars */
        for (let productName in items) {
            if (Object.prototype.hasOwnProperty.call(items, productName) && items[productName].min.indexOf('NONE') === -1) {
                productsTable.push({
                    name: items[productName].name,
                    versions: items[productName].min + '-->' + items[productName].max
                });
            } else {
                productsTable.push({
                    name: items[productName].name,
                    versions: t('All versions')
                });
            }
        }
        /* eslint-enable no-unused-vars */

        setProducts(productsTable);
        setVendors(retailers);
    }, [t]);

    const resolutionExtract = useCallback((specs) => {
        const resolution = [];
        specs.resolution.forEach((item) => {
            if (!item.product_id || !item.product_name) {
                return;
            }
            item.productName = item.product_name;
            if (!resolution.some((r) => r.productName.toUpperCase() === item.productName.toUpperCase())) {

                if (item.higher_than_version) {
                    item.resolution = t('Please update to a version greater than {{version}}', { version: item.higher_than_version });
                } else if (item.higher_than_or_equal_to_version) {
                    item.resolution = t('Please update to a version greater than or equal to {{version}}', { version: item.higher_than_or_equal_to_version });
                } else if (item.advisory_url) {
                    // eslint-disable-next-line no-useless-escape
                    item.resolution = t('Please follow <a href=\"{{advisoryUrl}}\" rel=`oopener noreferrer` target=`_blank`>this link</a>', { advisoryUrl: item.advisory_url });
                } else {
                    item.resolution = item.text;
                }

                resolution.push(item);
            }
        });

        setResolutions(resolution);
    }, [t]);

    const infoExtract = useCallback((specs) => {
        let details = [];

        if (specs.cvss_2_0?.score) {
            details.push({ label: t('OPSWAT Severity Score'), value: specs.severity_index });
        }

        if (specs.cve) {
            details.push({
                label: t('CVE'),
                value: (<OutboundLink href={CWE_URL + '?id=' + specs.cve} target='_blank' rel='noopener noreferrer'>{specs.cve}</OutboundLink>)
            });
        }

        if (specs.cvss_3_0 && specs.cvss_3_0.base_score) {
            details.push({ label: t('CVSS v3'), value: specs.cvss_3_0.base_score });
        }

        details.push({ label: t('Severity'), value: specs.severity });

        if (specs.cvss_2_0?.score) {
            details.push({ label: t('CVSS v2'), value: specs.cvss_2_0.score });
        }

        if (specs.last_modified_epoch) {
            details.push({ label: t('Last modified'), value: epochToDateTimeGmt(specs.last_modified_epoch) });
        }

        if (specs.published_epoch) {
            details.push({ label: t('Published'), value: epochToDateTimeGmt(specs.published_epoch) });
        }

        if (specs.cve_device_infection_risk && specs.cve_device_infection_risk !== '') {
            details.push({ label: t('Infection risk'), value: (Math.round(specs.cve_device_infection_risk * 10000) / 100) + '%' });
        }

        if (specs.cvss_2_0?.['access_complexity']) {
            details.push({ label: t('Access complexity'), value: specs.cvss_2_0.access_complexity });
        }

        if (specs.cvss_2_0?.['access_vector']) {
            details.push({ label: t('Access vector'), value: specs.cvss_2_0.access_vector });
        }

        if (specs.cvss_2_0?.authentication) {
            details.push({ label: t('Authentication'), value: specs.cvss_2_0.authentication });
        }

        if (specs.cvss_2_0?.['availability_impact']) {
            details.push({ label: t('Availability impact'), value: specs.cvss_2_0.availability_impact });
        }

        if (specs.cvss_2_0?.['confidentiality_impact']) {
            details.push({ label: t('Confidentiality impact'), value: specs.cvss_2_0.confidentiality_impact });
        }

        if (specs.cvss_2_0?.['integrity_impact']) {
            details.push({ label: t('Integrity impact'), value: specs.cvss_2_0.integrity_impact });
        }

        setInfo(details);
    }, [t]);

    useEffect(() => {
        (async () => {
            await Authenticator.init();
            let response;
            setLoading(true);
            try {
                const [promise] = metadefenderService.vulnerabilities.getInfo({ cve: props.cve });
                response = await promise;
            } catch (err) {
                logService.error(err); // eslint-disable-line no-console
                if (err.response?.data?.error?.code !== 404009) {
                    // If the error isn't from non-exist CVE, notify to user
                    notify({
                        message: t('Network error occurred, and your request could not be completed. Please refresh the page'),
                        type: NOTIFICATION_TYPES.CRITICAL
                    });
                }
            }

            const specs = response?.data;
            setData(specs);
            setLoading(false);
        })();
    }, [props.cve]);

    useEffect(() => {
        setIsNonExist(false);
        if (!data || !data?.cve) {
            setIsNonExist(true);
            setStatusCve(t('Requested CVE does not exist in our records'));
            return;
        }

        setIsAnalyzing(data.vulnerable_software_list && data.vulnerable_software_list.length === 0);
        setStatusCve(t('CVE is under analysis'));
        setRealCveValue(data.cve);
        setReferences(data.references);
        setDescription(data.description);

        if (data.opswat_product_info && data.opswat_product_info.length > 0) {
            productVendorExtract(data);
        }

        if (data.resolution) {
            resolutionExtract(data);
        }

        if (data.sha1 && data.hashes_count) {
            setHashes({
                hashes: data.sha1.map((hash) => ({ hash })), hashesCount: data.hashes_count
            });
        }

        infoExtract(data);
    }, [data, t, productVendorExtract, resolutionExtract, infoExtract]);

    const cveStatusComponent = useMemo(() => {
        if (isAnalyzing || isNonExist) {
            return (<CveStatus isUnderAnalysis={isAnalyzing} message={statusCve} />);
        }
        return null;
    }, [isAnalyzing, isNonExist, statusCve, loading]);

    const infoComponent = useMemo(() => (
        <Row>
            {info.map(({ label, value }) => (
                <Col xs={12} md={6} lg={6} key={label}>
                    <Row>
                        <Col>
                            <label>{label}</label>
                        </Col>
                        <Col>
                            <span>{value}</span>
                        </Col>
                    </Row>
                </Col>
            ))}
        </Row>
    ), [info]);

    const referencesComponent = useMemo(() => {
        if (references) {
            return (
                <Row>
                    <Col xs={12} md={3} lg={3}>
                        <label>{t('References')}</label>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                        <ul>
                            {references.map(({ url, index }) => (
                                <li key={index}><OutboundLink href={url} target='_blank' rel='noopener noreferrer'>{url}</OutboundLink></li>
                            ))}
                        </ul>
                    </Col>
                </Row>);
        }
        return null;
    }, [references, t]);

    const descriptionComponent = useMemo(() => {
        if (description) {
            return (
                <Row className='descriptionRow'>
                    <Col xs={12} md={3} lg={3}>
                        <label>{t('Description')}</label>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                        {description}
                    </Col>
                </Row>);
        }
        return null;
    }, [description, t]);

    const vendorsComponent = useMemo(() => {
        if (vendors && vendors.length > 0) {
            return (
                <div>
                    <Row>
                        <Col>
                            <h2>{t('Vendors affected by {{cve}}', { cve: realCveValue })}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table data={vendors} columns={[
                                {
                                    Header: t('Name'),
                                    accessor: 'name'
                                }
                            ]} />
                        </Col>
                    </Row>
                </div>);
        }
        return null;
    }, [vendors, realCveValue, t]);

    const productsComponent = useMemo(() => {
        if (products && products.length > 0) {
            return (
                <div>
                    <Row>
                        <Col>
                            <h2>{t('Products affected by {{cve}}', { cve: realCveValue })}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table data={products} columns={[
                                {
                                    Header: t('Name'),
                                    accessor: 'name'
                                },
                                {
                                    Header: t('Affected Versions'),
                                    accessor: 'versions',
                                    component: CveAffectedVersion
                                }
                            ]} />
                        </Col>
                    </Row>
                </div>);
        }
        return null;
    }, [products, realCveValue, t]);

    const resolutionsComponent = useMemo(() => {
        if (resolutions && resolutions.length > 0) {
            return (
                <div>
                    <Row>
                        <Col>
                            <h2>{t('Resolution')}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table data={resolutions} columns={[
                                {
                                    Header: t('Name'),
                                    accessor: 'productName'
                                },
                                {
                                    Header: t('Resolution'),
                                    accessor: 'resolution',
                                    component: CveResolution
                                }
                            ]} />
                        </Col>
                    </Row>
                </div>);
        }
        return null;
    }, [resolutions, t]);

    const hashesComponent = useMemo(() => {
        if (hashes && hashes.hashes && hashes.hashesCount) {
            return (
                <div>
                    <Row>
                        <Col>
                            <h2>{t('{{hashesCount}} Hashes affected by {{cve}}', { hashesCount: hashes.hashesCount, cve: realCveValue })}</h2>
                        </Col>
                    </Row>
                    <Row className='hashDescription'>
                        <Col>
                            {/* eslint-disable-next-line no-useless-escape */}
                            <span dangerouslySetInnerHTML={{ __html: t('Please contact our <a href=\"https://www.opswat.com/contact\">sales team</a> for access to the full list of hashes associated with NIST\'s published National Vulnerability Database') }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table data={hashes.hashes} columns={[
                                {
                                    Header: t('Hash'),
                                    accessor: 'hash'
                                }
                            ]} />
                        </Col>
                    </Row>
                </div>);
        }
        return null;
    }, [hashes, realCveValue, t]);

    const bodyComponent = useMemo(() => {
        if (!isNonExist) {
            return (
                <div>
                    {infoComponent}
                    {referencesComponent}
                    {descriptionComponent}
                    {vendorsComponent}
                    {productsComponent}
                    {resolutionsComponent}
                    {hashesComponent}
                </div>);
        }
        return null;
    }, [isNonExist, infoComponent, referencesComponent, descriptionComponent, vendorsComponent, productsComponent, resolutionsComponent, hashesComponent]);

    const cveMainComponent = useMemo(() => {
        if (loading) {
            return (
                <div className='spinnerComponent'>
                    <Spinner />
                </div>);
        }
        return (
            <div>
                {cveStatusComponent}
                {bodyComponent}
            </div>);
    }, [loading, cveStatusComponent, bodyComponent]);

    if (!ready) {
        return null;
    }
    return (
        <div className='cveDetail'>
            <Row>
                <Col md={6} lg={6} xs={12}>
                    <h1>{realCveValue}</h1>
                </Col>
                <Col md={6} lg={6} xs={12} className='seeFullListBtnWrapper'>
                    <InternalLink
                        to={'/vulnerabilities'}
                        state={{ CVEListData: cvesData }}
                        className='seeFullListBtn btn btn-primary'
                    >{t('See full list')}</InternalLink>
                </Col>
            </Row>

            <div className='mainComponent'>
                {cveMainComponent}
            </div>
        </div>
    );
};

CveDetails.propTypes = {
    cve: PropTypes.string,
    notify: PropTypes.func,
    location: PropTypes.object
};

export default CveDetails;
